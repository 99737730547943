@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-ExtraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-Regular.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-Italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-ExtraBold.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphie';
  src: url('../fonts/Graphie/Graphie-ExtraBoldItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}
